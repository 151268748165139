/** @jsx jsx */
import React from "react";
import { Link as RouteLink, useStaticQuery, graphql } from "gatsby";
import { jsx, Box, Link, Button } from "theme-ui";
import CookieConsent, { Cookies } from "react-cookie-consent";

// import Nav from "./nav";
import Seo from "./seo";

const Layout = ({ children, seo }) => {
  const { site } = useStaticQuery(query);
  // Merge default and page-specific SEO values
  const fullSeo = { ...site.siteMetadata, ...seo };

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Súhlasím"
        enableDeclineButton
        declineButtonText="Neskôr"
        cookieName="nabytok-design-ga-cookies"
        ButtonComponent={props => (
          <Button variant="simple" sx={props.style} onClick={props.onClick}>
            {props.children}
          </Button>
        )}
        buttonStyle={{
          backgroundColor: "primary",
          ":hover": {
            backgroundColor: "secondary",
          },
        }}
        declineButtonStyle={{
          backgroundColor: "secondary",
          color: "accent",
          ":hover": {
            backgroundColor: "secondaryLight",
            color: "accent",
          },
        }}
      >
        Pre lepší online zážitok používame súbory “cookies”. Súhlasíte so
        spracovaním osobných údajov?{" "}
        <Link as={RouteLink} sx={{ color: "secondary" }} to={"/cookies"}>
          Pre bližšie vysvetlenie čo sú cookies kliknite sem
        </Link>
      </CookieConsent>
      {/* <CookieBanner
      message="Cookie banner message"
      wholeDomain={true}
      onAccept={() => {}}
      onAcceptPreferences={() => {}}
      onAcceptStatistics={() => {}}
      onAcceptMarketing={() => {}}
    /> */}
      <Box style={{ margin: `0 auto`, overflowX: `hidden` }}>
        {/* <Seo seo={fullSeo} siteName={siteName} favicon={favicon} /> */}
        <Seo
          seo={fullSeo}
          siteName={site.siteMetadata.title}
          favicon={fullSeo.favicon}
        />
        {/* <Nav /> */}
        {/* <Box as="header" sx={{ textAlign: "center", my: "1rem", fontSize: 2 }}>
          Na stránke sa momentálne pracuje. Niektoré časti sa preto môžu
          nesprávne zobraziť. Ďakujeme za pochopenie.
        </Box> */}
        <Box as="main">{children}</Box>
        <Box
          as="footer"
          sx={{
            marginBottom: `1.5rem`,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ mx: [5, "auto"], textAlign: "center" }}>
            © {new Date().getFullYear()} | Pripravené s ❤️ v{" "}
            {site.siteMetadata.title}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Layout;

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`;
