import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ seo, siteName, favicon }) => {
  const getMetaTags = () => {
    const tags = [];

    if (seo.metaTitle) {
      tags.push(
        {
          property: "og:title",
          content: seo.metaTitle,
        },
        {
          name: "twitter:title",
          content: seo.metaTitle,
        }
      );
    }
    if (seo.metaDescription) {
      tags.push(
        {
          name: "description",
          content: seo.metaDescription,
        },
        {
          property: "og:description",
          content: seo.metaDescription,
        },
        {
          name: "twitter:description",
          content: seo.metaDescription,
        }
      );
    }
    if (seo.shareImage) {
      const imageUrl =
        (process.env.GATSBY_ROOT_URL || "http://localhost:8000") +
        seo.shareImage.publicURL;
      tags.push(
        {
          name: "image",
          content: imageUrl,
        },
        {
          property: "og:image",
          content: imageUrl,
        },
        {
          name: "twitter:image",
          content: imageUrl,
        }
      );
    }
    if (seo.article) {
      tags.push({
        property: "og:type",
        content: "article",
      });
    }
    tags.push({ name: "twitter:card", content: "summary_large_image" });

    return tags;
  };

  const metaTags = getMetaTags();

  return (
    <Helmet
      title={seo.metaTitle}
      titleTemplate={`%s | ${siteName}`}
      link={[
        {
          rel: "icon",
          href: !!favicon && favicon.publicURL,
        },
        // {
        //   rel: "stylesheet",
        //   href: "https://fonts.googleapis.com/css?family=Staatliches",
        // },
        // {
        //   rel: "stylesheet",
        //   href:
        //     "https://cdn.jsdelivr.net/npm/uikit@3.2.3/dist/css/uikit.min.css",
        // },
      ]}
      script={
        [
          // {
          //   src:
          //     "https://cdnjs.cloudflare.com/ajax/libs/uikit/3.2.0/js/uikit.min.js",
          // },
          // {
          //   src:
          //     "https://cdn.jsdelivr.net/npm/uikit@3.2.3/dist/js/uikit-icons.min.js",
          // },
          // {
          //   src: "https://cdnjs.cloudflare.com/ajax/libs/uikit/3.2.0/js/uikit.js",
          // },
        ]
      }
      meta={metaTags}
    />
  );
};

export default SEO;